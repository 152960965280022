import React from 'react'
import { graphql, navigate } from 'gatsby'
import styled from 'styled-components'
import { Image, breakpoints, City, sendDataToGTM } from '@ecommerce/shared'
import Layout from '../components/Layout'
import { TabMenuMobile } from '../components/TabMenu'
import withPageTransition from '../components/withPageTransition'
import { BlockType, Block, ContentfulImage } from '../graphql/contentfulTypes'
import MomentsList from '../components/Moments/MomentsCardsList'
import { MomentCardType } from '../components/Moments/MomentCard'
import { useTabs } from '../hooks/useTabs'
import { FlatLocationProductCategory } from '../types/PgPages'

const Wrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 231px);
  display: flex;
  flex-direction: column;

  .Category__tab-menu-mobile {
    .compact-menu {
      height: 65px;
      position: fixed;
    }
  }

  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 194px);
  }

  @media (${breakpoints.desktop.min}) {
    min-height: calc(100vh - 212px);
  }
`
const Title = styled.div`
  height: 70px;
  margin-top: 15px;
  padding: 25px 35px;

  span {
    font-size: 24px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.black};
  }

  @media screen and (${breakpoints.desktop.min}) {
    margin-top: 25px;
    padding-left: 135px;
  }
`
const WrapperItems = styled.div`
  padding: 0 8px 30px;
  margin-bottom: 32px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 35px;
  gap: 10px;

  @media screen and (${breakpoints.tabletPortrait.min}) {
    gap: 30px;
  }

  @media screen and (${breakpoints.desktop.min}) {
    padding: 0 135px;
  }
`

const WrapperItem = styled.div`
  width: 80px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: auto;

  .Category__box {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
    display: flex;
    justify-content: center;
    align-items: center;

    &-image {
      width: 100%;
      height: 100%;
    }
  }

  span {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.dark};
  }

  @media (${breakpoints.tabletPortrait.min}) {
    width: 132px;

    .Category__box {
      height: 132px;
      width: 132px;
    }

    span {
      padding-top: 15px;
      font-size: 18px;
    }
  }

  @media (${breakpoints.desktop.min}) {
    padding: 10px 10px;
  }
`

interface Category {
  id: string
  name: string
  slug: string
  image: ContentfulImage
}

interface Edges {
  node: {
    title: string
    slug: string
    blocks: Block<Category & MomentCardType>[]
  }
}

interface Props {
  data: {
    allContentfulPgPage: {
      edges: Edges[]
    }
  }
  pageContext: {
    currentCity: City
    productCategories: FlatLocationProductCategory[]
  }
}

function Category(props: Props) {
  const { currentCity, productCategories } = props.pageContext
  const blocks =
    props.data.allContentfulPgPage.edges && props.data?.allContentfulPgPage?.edges[0]
      ? props.data.allContentfulPgPage.edges[0]?.node?.blocks
      : []
  const { tabs } = useTabs()

  const onNavigate = (slug: string, name: string) => {
    sendDataToGTM({ event: 'categoryClick', categoryName: name })
    navigate(slug.startsWith('/') ? `/${currentCity.slug}${slug}` : `/${currentCity.slug}/category/${slug}`)
  }

  return (
    <Layout
      categories={productCategories}
      id="layout"
      title="Categorías"
      pathName={`/${currentCity.slug}/category`}
      currentCity={currentCity}
    >
      <Wrapper>
        {blocks.map((block) => {
          switch (block.blockType) {
            case BlockType.CATEGORY: {
              return (
                <>
                  {block?.title && (
                    <Title>
                      <span>{block.title}</span>
                    </Title>
                  )}
                  <WrapperItems>
                    {block.items.map(({ id, name, slug, image }) => (
                      <WrapperItem key={id} onClick={() => onNavigate(slug, name)}>
                        <div className="Category__box">
                          <Image className="Category__box-image" src={image.file.url} alt={name} />
                        </div>
                        <span>{name}</span>
                      </WrapperItem>
                    ))}
                  </WrapperItems>
                </>
              )
            }

            case BlockType.MOMENT_CARD: {
              const items = block.items.map((item) => ({ ...item, slug: `/${currentCity.slug}/momento/${item.slug}` }))
              return (
                <>
                  {block?.title && (
                    <Title>
                      <span>{block.title}</span>
                    </Title>
                  )}
                  <MomentsList items={items} />
                </>
              )
            }

            default:
              return null
          }
        })}
      </Wrapper>
    </Layout>
  )
}

export default withPageTransition(Category)

export const query = graphql`
  query CategoryQuery($categoryId: String!) {
    allContentfulPgPage(filter: { contentful_id: { eq: $categoryId } }) {
      edges {
        node {
          slug
          title
          blocks {
            blockType
            title
            items {
              ... on ContentfulCategory {
                id
                slug
                name
                image {
                  file {
                    url
                  }
                }
              }
              ... on ContentfulMlMomentCard {
                id
                title
                subtitle
                slug
                image {
                  file {
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
